.copied-to-clipboard-toast {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 41px;
    border-radius: 2px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    background-color: #f3f5f7;
}

.toast-text {
    font-size: 13px;
    font-weight: normal;
    line-height: 1.46;
    color: #1e2228;
}
